<template>
  <div class="mall-wrapper">
    <div v-if="detail">
      <div class="_banner">
        <img :src="detail.head_img" alt="">
      </div>

      <!-- 普通 -->
      <template v-if="detail.page_type === 0">
        <div class="_divider">
          <van-divider :style="{ color: '#cbcbcb', borderColor: '#cbcbcb', padding: '0 16px', fontSize: '12px' }">共有
            {{ dataList.length }} 项权益
          </van-divider>
        </div>
        <div class="_list">
          <div class="_item" v-for="item of dataList"
               @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
            <van-row>
              <van-col span="7">
                <img class="_pic" :src="item.logo_img" alt="">
              </van-col>
              <van-col span="16">
                <div class="_name">{{ item.merchant_name }}</div>
                <div class="_desc">{{ item.merchant_title }}</div>
              </van-col>
            </van-row>
            <span class="_btn"><img
              src="../status/img/btn_view.png"></span>
          </div>
        </div>
      </template>

      <!-- 普通 -->
      <template v-if="detail.page_type == 3">
        <div class="_divider">
          <van-divider :style="{ color: '#cbcbcb', borderColor: '#cbcbcb', padding: '0 16px', fontSize: '12px' }">共有
            {{ dataList.length }} 项权益
          </van-divider>
        </div>
        <div class="_list">
          <div v-for="item of dataList"
               @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
            <img class="_pic" style="  width: 100%;" :src="item.logo_img">
          </div>
        </div>
      </template>

      <!-- 食 -->
      <template v-if="detail.page_type === 1 && menuData">
        <template v-if="currMallId==4">
          <show_img id="144d3151-f9cf-4543-869e-e7987dd3144d"/>
          <show_img id="fb10c89c-6d70-4c09-89b2-1920379eb44a"/>
        </template>
        <template v-else-if="currMallId==60">
          <show_img id="7b0f9a89-34db-467f-b9af-f0e20e14087a"></show_img>
        </template>
        <template v-else-if="currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35">
          <div class="_sub_title" v-if="currCardType==1"><span v-if="menuData.houseList.length>0">商圈美食</span>低至5折
          </div>
          <div class="_sub_title" v-else><span v-if="menuData.houseList.length>0">商圈美食</span></div>
          <div class="_house_list" v-if="menuData.houseList.length>0">
            <van-row gutter="12">
              <van-col span="12" v-for="item of menuData.houseList">
                <div class="_item">
                  <div class="_views"
                       @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
                    <img class="_pic" :src="item.logo_img" alt="">
                  </div>
                </div>
              </van-col>
              <van-col span="12" v-if="menuData.houseList.length%2>0">
              </van-col>
            </van-row>
          </div>
          <van-divider :style="{ borderColor: '#cbcbcb', padding: '0 16px', marginTop: 0 }"/>
        </template>
        <template v-if="currMallId!=4">
          <van-divider :style="{ borderColor: '#cbcbcb', padding: '0 16px', marginTop: 0}"
                       v-if="menuData.foodList.length>0 && detail.page_type==2 "/>
          <div class="_sub_title" style="margin-top: -0.1rem" v-if="menuData.foodList.length>0">
            <span v-if="menuData.foodList.length>0">全川精选美食{{currMallId}}</span></div>
          <div class="_food_list" v-if="menuData.foodList.length>0">
            <div class="_item" v-for="item of menuData.foodList">
              <img class="_pic" :src="item.logo_img" @click="$router.push('/activity?id='+item.id)">
            </div>
          </div>
        </template>


        <div
          v-if="currCardType==1 && (currMallId==4 || currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35)">
          <div class="_sale_list_a">
            <show_img id=" c3c4f3e5-4c8a-42a5-9377-374ad46cb75e"/>
            <img src="http://pic.eaworks.cn/scbank/uploads/2023731/426757.png">
            <span class="_left" @click="onClickPageLink('/activity?id=437cc8ec-8060-41e4-938b-0e1d345f73c2')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=3079a711-2af5-44ff-b391-04c730858e9e')"></span>
          </div>
          <div class="_sale_list_b">
            <show_img id="191da3ef-c5f3-42a2-acb5-a35324367d6c"/>
<!--            <img src="http://pic.eaworks.cn/scbank/uploads/20231017/5337207.png">-->
            <span class="_left" @click="onClickPageLink('/activity?id=6d40dae9-2c37-48f7-9b11-2513e82cde63')"></span>
            <span class="_middle" @click="onClickPageLink('/activity?id=a067b9e0-1c6b-460e-a2c4-6e1b22b7dea6')"></span>
            <span class="_right"
                  @click="onClickPageLink('https://h5.vc.nervenets.cn/?v=Xk2irXp8t4nkbyFm2J2WntDGyWNKSpJJ#/mtmt?id=1137560&mId=1040&uId=799313&businesserId=0&checkOrderId=')"></span>
          </div>
        </div>
        <div
          v-if="currCardType==2 && (currMallId==4 ||currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35) ">
          <div class="_sale_list_a">
            <img src="http://pic.eaworks.cn/scbank/uploads/202282/3073951.png">
            <span class="_left" @click="onClickPageLink('/activity?id=8a9eac4c-1f34-4a37-a979-95fe766e7831')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=0df9ede0-2642-4d09-b277-e2397ca0274c')"></span>
          </div>
          <div class="_sale_list_a">
            <img src="http://pic.eaworks.cn/scbank/uploads/2022831/6438203.png">
            <span class="_left" @click="onClickPageLink('/activity?id=6cc573c3-5623-43bb-ac45-16d7247b9a62')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=f52d55c2-8537-4caf-9b07-e4ce996f2108')"></span>
          </div>
        </div>
        <div
          v-if="currCardType==3 && (currMallId==4 ||currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35)">
          <div class="_sale_list_a">
            <img src="http://pic.eaworks.cn/scbank/uploads/2022816/8935105.png">
            <span class="_left" @click="onClickPageLink('/activity?id=77077853-ede5-4c39-af3b-84272375655c')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=6183c1f1-6d72-4e6e-b120-b8e727e8aaeb')"></span>
          </div>
          <div class="_sale_list_a">
            <img src="http://pic.eaworks.cn/scbank/uploads/2022831/1865769.png">
            <span class="_left" @click="onClickPageLink('/activity?id=add9b8a1-f983-48bf-8f49-22ddede6d163')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=d98fdf9d-2bb0-4591-a8f3-2e2621536e6e')"></span>
          </div>
        </div>
        <div
          v-if="currCardType==4 && (currMallId==4 ||currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35)">
          <div class="_sale_list_a">
            <img src="http://pic.eaworks.cn/scbank/uploads/2022810/261264.png">
            <span class="_left" @click="onClickPageLink('/activity?id=b335640e-47c9-470c-bf39-efcd9aed2ef4')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=001c6ae3-2bc3-4680-8678-cad338167d49')"></span>
          </div>
          <div class="_sale_list_a">
            <img src="http://pic.eaworks.cn/scbank/uploads/2022831/1264850.png">
            <span class="_left" @click="onClickPageLink('/activity?id=c2f4e4e1-2f93-4125-80fd-762a74ffa5b3')"></span>
            <span class="_right" @click="onClickPageLink('/activity?id=b34b39f2-1045-46fc-9519-2e4da512f880')"></span>
          </div>
        </div>
        <van-divider :style="{ borderColor: '#cbcbcb', padding: '0 16px' }" v-if="menuData.vipMailList.length>0"/>
        <div class="_sub_title" style="margin-top: -0.1rem"
             v-if="menuData.vipMailList.length>0 && (currMallId==4 || currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35 || currMallId==60)">
          <span>品质生活</span>
        </div>
        <div class="_food_list"
             v-if="menuData.vipMailList.length>0 && (currMallId==4 || currMallId==15 || currMallId==15 || currMallId==25 || currMallId==35 || currMallId==60 )">
          <div class="_item" v-for="item of menuData.vipMailList" >
            <img class="_pic" :src="item.logo_img"
                 @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
          </div>
        </div>


        <!--        <div class="_vip_mail_list" v-if="menuData.vipMailList.length>0" >-->
        <!--          <van-row gutter="12">-->
        <!--            <van-col span="12" v-for="item of menuData.vipMailList">-->
        <!--              <div class="_item">-->
        <!--                <img class="_pic" :src="item.logo_img"-->
        <!--                     @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">-->
        <!--              </div>-->
        <!--            </van-col>-->
        <!--          </van-row>-->
        <!--        </div>-->
      </template>

      <!-- 购 -->
      <template v-if="detail.page_type === 2 && menuData">
        <!--        <div class="_sub_title _sub_title2" v-if="currCardType!=1"><span>七大商圈 约惠嗨购</span> 最高立减300元</div>-->
        <div class="_sub_title _sub_title2" v-if="currCardType!=1 && currCardType!=9"><span
          v-if="menuData.houseList.length>0">五大商圈 约惠嗨购</span>&nbsp;
        </div>
        <div v-if="currMallId==6">
          <show_img id="69a056d6-97aa-4d0d-8926-5bea3afc0d3c"></show_img>
          <show_img id="c27e3bd1-0f25-4362-bfad-e1e949805bff"></show_img>
          <show_img id="2bc5c47b-57c8-4189-a1a2-d9e38994d50e"></show_img>
        </div>
        <div v-else-if="currMallId==54">
          <show_img id="2b128e29-4e72-4878-b386-bac022975c2e"></show_img>
          <show_img id="79eaca21-5289-4504-abf6-4348c3ba45d8"></show_img>
          <show_img id="d6c78abd-c332-45f4-872b-14a23c05a627"></show_img>
        </div>
        <div class="_house_list _house_list2" v-else-if="menuData.houseList.length>0">
          <van-row gutter="12">
            <van-col span="8" v-for="item of menuData.houseList">
              <div class="_item">
                <div class="_views">
                  <img class="_pic" :src="item.logo_img"
                       @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
                </div>
              </div>
            </van-col>
          </van-row>
        </div>

        <div v-if="currCardType==1">
          <van-divider :style="{ borderColor: '#cbcbcb', padding: '0 16px', marginTop: 0}"/>
          <div class="_sub_title _sub_title2" style="margin-top: -0.1rem"><span>尊享会员权益</span></div>
          <div class="_vip_benefit_list">
            <div class="_item" v-for="item of menuData.benefitList">
              <img :src="item.logo_img"
                   @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
            </div>
          </div>
        </div>
        <van-divider :style="{ borderColor: '#cbcbcb', padding: '0 16px', marginTop: 0}"
                     v-if="menuData.vipMailList.length>0"/>
        <div class="_sub_title _sub_title2" style="margin-top: -0.1rem"><span
          v-if="menuData.vipMailList.length>0">优选美酒</span></div>
        <div class="_vip_mail_list" style="float: left" v-if="menuData.vipMailList.length>0">
          <van-row gutter="12">
            <van-col span="12" v-for="item of menuData.vipMailList">
              <div class="_item">
                <img class="_pic" :src="item.logo_img"
                     @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
              </div>
            </van-col>
          </van-row>
        </div>
        <van-divider :style="{ borderColor: '#cbcbcb', padding: '0 16px', marginTop: '0.4rem'}"
                     v-if="menuData.foodList.length>0 "/>
        <div class="_sub_title _sub_title2"><span v-if="menuData.foodList.length>0">生活福利专享</span></div>
        <div class="_food_list" v-if="menuData.foodList.length>0">
          <div class="_item" v-for="item of menuData.foodList">
            <img class="_pic" :src="item.logo_img"
                 @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)">
          </div>
        </div>
      </template>
      <div style="font-size: 0.3rem; text-align: center; line-height: 3em;"></div>
      <div class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
        <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" >
      </div>
      <div class="_bottom_fixed">
        <div class="_height"></div>
        <div class="_bottom_back">
          <span class="_back_home" @click="$router.push('/home')"></span>
          <span class="_back_prev" @click="$router.push('/card?type='+currCardType)"></span>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import page_links from "../mixin/page_links";
import wx from 'weixin-js-sdk'
import show_img from "./components/show_img"

export default {
  mixins: [page_links],
  components: {show_img},

  data() {
    return {
      currCardType: 0,
      currMallId: 0,
      menuData: {
        houseList: [],
        vipMailList: [],
        foodList: [],
        benefitList: []
      },
      detail: null,
      dataList: [],
    };
  },

  created() {
    console.log("-> [index.vue] created");
  },

  mounted() {
    this.getJsInfo();
    console.log("-> [index.vue] mounted");
    this.currCardType = Number(this.$route.query.card) || 1;
    console.info("currCardType========>" + this.currCardType);
    this.currMallId = Number(this.$route.query.type) || 1;
    console.info("currMallId========>" + this.currCardType);
    console.info(this.currCardType);
    this.getMallDetail();
    this.getJsInfo();
  },

  methods: {
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    onClickItem(item) {
      if (item.jump_type == null || item.jump_type == "") {
        this.$router.push("/activity?id=" + item.id);
      } else {
        window.open(item.jump_type)
      }
    },

    async getMallDetail() {
      // 获取项目的详细信息
      let {data} = await this.$ajax.gateway("/api/api_get_welfare_detail", {
        "METHOD": "get",
        "welfare_id": this.currMallId
      });
      this.detail = data;
      this.getMerchantList();
    },
    async getMerchantList() {
      this.menuData = {
        houseList: [],
        vipMailList: [],
        foodList: [],
        benefitList: []
      };
      if (this.detail.page_type == 0 || this.detail.page_type === 3) {
        // 获取商户项目
        let {data} = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 0,
          "pageSize": 99
        });
        console.info(data);
        this.dataList = data;
      } else if (this.detail.page_type == 1) {
        // 获取商户项目
        let res = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 1,
          "pageSize": 99
        });
        this.menuData.houseList = res.data;
        // 获取商户项目
        let res1 = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 2,
          "pageSize": 99
        });
        console.info("==========>1", res1);
        this.menuData.foodList = res1.data;
        // 获取商户项目
        let res2 = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 3,
          "pageSize": 10
        });
        console.info("==========>2", res2);
        this.menuData.vipMailList = res2.data;
      } else if (this.detail.page_type == 2) {
        // 获取商户项目
        let res = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 1,
          "pageSize": 99
        });
        this.menuData.houseList = res.data;

        // 获取商户项目
        let res1 = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 6,
          "pageSize": 99
        });
        this.menuData.foodList = res1.data;
        // 获取商户项目
        let res2 = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 5,
          "pageSize": 2
        });
        this.menuData.vipMailList = res2.data;

        // 获取商户项目
        let res3 = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
          "METHOD": "get",
          "welfare_id": this.currMallId,
          "list_type": 4,
          "pageSize": 10
        });
        this.menuData.benefitList = res3.data;
      }
    },

  }
};
</script>

<style lang="less" scoped>
.mall-wrapper {
  background: #ebebeb;
  position: relative;

  ._banner {
    img {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  /* 商家列表 */

  ._list {
    padding: 0.1rem 0.4rem 0.2rem;
    min-height: 10rem;

    ._item {
      position: relative;
      margin-bottom: 0.4rem;
      background: #fff;
      padding: 0.2rem;
      border-radius: 0.2rem;
      box-shadow: 0 6px 12px 0 rgba(30, 38, 51, 0.12);

      img._pic {
        display: block;
        height: 2.1rem;
        margin-left: 0.2rem;
      }

      ._name {
        padding-top: 0.35rem;
        line-height: 2em;
        font-size: 0.42rem;
      }

      ._desc {
        color: #999;
        font-size: 0.32rem;
      }

      ._btn {
        position: absolute;
        top: 0.4rem;
        right: 0.5rem;

        img {
          width: 1.7rem;
        }
      }
    }
  }

  // 食，标题
  ._sub_title {
    font-size: 0.32rem;
    color: #ec7453;
    padding: 0.2rem 0.3rem 0.2rem 0.2rem;
    margin-top: 0.4rem;

    span {
      display: inline-block;
      line-height: 0.55rem;
      padding: 0 0.4rem 0 0.5rem;
      background: url("../status/img/mail_title_bg.png") no-repeat right center;
      background-size: auto 100%;
      font-size: 0.4rem;
      letter-spacing: 0.05rem;
      color: #595757;
      margin-right: 0.15rem;
    }

    &._sub_title2 {
      text-align: right;
      padding-left: 0;
      color: #888;
      text-decoration: underline;

      span {
        background: url("../status/img/mail_title_bg2.png") no-repeat right center;
        background-size: 70% auto;
        float: left;
        text-decoration: none;
        border-radius: 100px;
      }
    }
  }

  ._house_list {
    position: relative;
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;

    ._item {
      background: #fff;
      border-radius: 0.4rem;
      padding: 0.2rem;
      margin-bottom: 0.4rem;
      //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.01);
      ._views {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        border-radius: 0.3rem;
        text-align: center;

        img._pic {
          display: block;
          width: 100%;
          height: 5.8rem;
          border-radius: 0.3rem 0.3rem 0 0;
        }

        ._title {
          font-size: 0.45rem;
          line-height: 1.8em;
          color: #333;
        }

        ._dsc {
          font-size: 0.2rem;
          color: #888;
          padding-bottom: 0.4rem;
        }
      }

      ._foot {
        text-align: center;

        span.btn_h {
          display: inline-block;
          width: 2.9rem;
          padding: 0.1rem 0 0.3rem;
          line-height: 0.6rem;
          background: url("../status/img/btn_h1.png") no-repeat right center;
          background-size: 100% auto;
          text-align: center;
          color: #fff;
          font-size: 0.3rem;
        }
      }
    }

    &._house_list2 {
      ._item {
        margin-bottom: 0.3rem;

        ._views {
          img._pic {
            height: 3.8rem;
          }

          ._title {
            font-size: 0.4rem;
            line-height: 2em;
          }
        }

        ._foot {
          margin: -0.1rem auto;

          span.btn_h {
            width: 2.4rem;
            padding: 0.15rem 0 0.3rem;
            line-height: 0.5rem;
            background: url("../status/img/btn_h2.png") no-repeat right center;
            background-size: 100% auto;
          }
        }
      }
    }
  }

  ._food_list {
    position: relative;
    padding: 0.5rem 0.4rem;

    ._item {
      margin: 0 auto 0.3rem;
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

      img._pic {
        display: block;
        border-radius: 0.3rem;
        width: 100%;
      }
    }
  }

  ._sale_list_a, ._sale_list_b {
    position: relative;
    //padding: 0.25rem;
    img {
      display: block;
      width: 100%;
    }

    span {
      display: inline-block;
      width: 4rem;
      height: 1.9rem;
      position: absolute;
    }
  }

  ._sale_list_a {
    span._left {
      left: 0.7rem;
      bottom: 0.5rem;
    }

    span._right {
      right: 0.7rem;
      bottom: 0.5rem;
    }
  }

  ._sale_list_b {
    span._left {
      width: 2.4rem;
      height: 3rem;
      left: 0.7rem;
      bottom: 0.6rem;
    }

    span._middle {
      width: 2.4rem;
      height: 3rem;
      left: 3.3rem;
      bottom: 0.5rem;
    }

    span._right {
      width: 2.5rem;
      height: 3.3rem;
      right: 0.7rem;
      bottom: 0.5rem;
    }
  }

  ._vip_mail_list {
    position: relative;
    padding: 0.2rem 0.4rem;

    ._item {
      //background: #fff;
      //border-radius: 0.3rem;
      //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

      img._pic {
        display: block;
        border-radius: 0.3rem;
        width: 100%;
      }
    }
  }

  ._vip_benefit_list {
    padding: 0.5rem 0.3rem;

    ._item {
      margin-bottom: 0.4rem;

      img {
        display: block;
        width: 100%;
      }
    }
  }
  ._right_bar_new_card{
    position: fixed;
    top: 0.3rem;
    right: 0rem;
    img {
      height: 2.7rem;
    }
  }
}
</style>
